<template>
  <div>
    <Header></Header>
    <div class="cpal1">
      <img class="cpalA1" src="@/assets/cpal.png" alt="" />
      <div class="cpalB1">产品案例 - 执法办案云平台</div>
      <div class="cpalC1">LAW ENFORCEMENT AND CASE HANDLING</div>
    </div>
    <div class="body">
      <div>
        <div class="cpszh">执法办案云平台</div>
        <div class="bodyTitle">
          <div class="product">LAW ENFORCEMENT AND CASE HANDLING</div>
          <!-- <div class="more">
            <div class="datetime">2022-10-23</div>
          </div> -->
        </div>
      </div>
      <div class="bodyContent">
        <div>
          <img class="cpalb1" src="@/assets/执法办案云平台.png" alt="" />
          <img class="cpalb1" src="@/assets/执法办案云平台2.png" alt="" />
        </div>
        <div class="fontA11">
          &emsp;&emsp;依托城市物联网、云计算、多网融合等现代化技术，运用数字基础资源、多维信息感知、协同工作处置、智能督察考评、预警决策分析等手段，量化城市管理部件、事件标准，建设城市管理、执法服务平台，形成具备高度感知、互联、智能的城市管理新模式。依托自主研发的“六位一体”综合执法平台，实现了执法办案全程无纸化、自动化、即时化。
        </div>
      </div>
      <div class="LastNext">
        <div class="last" @click="eightL">上一案例：{{ last }}</div>
        <div class="next" @click="eightN">下一案例：{{ next }}</div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/views/header.vue";
import Footer from "@/views/footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      last: "工业制冷云平台",
      next: "执法办案云平台",
    };
  },
  methods: {
    eightL() {
        this.$router.push({ name: "productCase7" });
    },
    eightN() {
        this.$router.push({ name: "productCase9" });
    }
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.lbt {
  width: 100%;
}
.body {
  width: 1200px;
  margin: 0 auto;
  padding-bottom: 80px;
  background: #fff !important;
}
.cpszh {
  font-size: 24px;
  padding: 40px 0 4px 32px;
  color: #183f85;
  letter-spacing: 1px;
}
.bodyTitle {
  display: flex;
  justify-content: space-between;
  margin-right: 32px;
}
.product {
  font-size: 24px;
  color: #bfbfbf;
  margin-left: 32px;
  letter-spacing: 1px;
}
.more {
  display: flex;
}
.datetime {
  font-size: 20px;
  color: #7d7d7d;
  letter-spacing: 1px;
}
.bodyContent {
  display: block;
  border-bottom: 1px solid #d8d8d8;
}
.cpalb1 {
    width: 100%;
  margin: 24px 32px;
}
.fontA1 {
  font-size: 20px;
  letter-spacing: 1px;
  margin: 0 24px 0 32px;
  line-height: 40px;
}
.fontA11 {
  font-size: 20px;
  letter-spacing: 1px;
  margin: 0 24px 46px 32px;
  line-height: 40px;
}
.LastNext {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
  font-size: 20px;
  color: #7d7d7d;
  letter-spacing: 1px;
}
.last {
    cursor: pointer;
}
.next {
    cursor: pointer;
}
.cpal1 {
  position: relative;
}
.cpalA1 {
  width: 100%;
}
.cpalB1 {
  position: absolute;
  top: 200px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  font-size: 60px;
  color: #fff;
  line-height: 33px;
  letter-spacing: 1px;
}
.cpalC1 {
  position: absolute;
  top: 280px;
  transform: translate(-50%);
  left: 50%;
  width: 1200px;
  margin-left: 32px;
  font-size: 24px;
  color: #bfbfbf;
  line-height: 33px;
  letter-spacing: 1px;
}
</style>
